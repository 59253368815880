.intro {
  background: url(../../images/noise.gif);
  background-size: 300px auto;
  background-color: #494949;
  color: white;
  padding-top: 32px;
}

.intro__title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

@media (min-width: 768px) {
  .intro__title {
    flex-direction: row;
  }
}

.intro__avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .intro__avatar {
    width: 100px;
    height: 100px;
  }
}

.intro__name {
  margin: 0;
  font-family: 'Merriweather', serif;
  font-size: 40px;
}

@media (min-width: 768px) {
  .intro__name {
    font-size: 64px;
  }
}

@media (min-width: 1024px) {
  .intro__name {
    font-size: 74px;
  }
}

.intro__subtitle {
  font-family: 'Merriweather', serif;
  font-size: 20px;
}

@media (min-width: 768px) {
  .intro__subtitle {
    font-size: 24px;
  }
}

.intro__blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 72px;
}
