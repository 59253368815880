.stack {
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 16px;
  max-width: 600px;
  flex-grow: 0;
  flex-shrink: 1;
}

@media (min-width: 768px) {
  .stack {
    font-size: 16px;
  }
}

.stack__title {
  font-family: 'Merriweather', serif;
  margin: 0;
  margin-bottom: 16px;
}

.stack__text {
  margin: 0;
  margin-bottom: 16px;
}

.stack__text:last-child {
  margin: 0;
}

.stack ul {
  list-style: circle;
  margin: 0;
  padding: 0;
  padding-left: 1em;
}

.stack li {
  margin-bottom: 16px;
}

.stack .stack__title span {
  font-size: 20px;
  opacity: 1;
}

.stack span {
  font-family: monospace;
  padding-inline: 0.3em;
  outline: 2px solid rgba(255, 255, 255, 0.2);
  outline-offset: -3px;
  border-radius: 5px;
  font-size: 16px;
  transition: opacity 0.2s;
  opacity: 0.6;
}

.stack span:hover {
  opacity: 1;
}
