.link_with-icon::before {
  content: '';
  /* Расположение */
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: -10%;
  margin-right: 0.3em;
  /* Параметры иконки */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.link_with-icon:hover::before {
  opacity: 1;
}

.link_with-icon[href*='github.com']::before {
  background-image: url(../../images/icons/icon__github_light.svg);
}

.link_with-icon[href*='mailto:']::before {
  background-image: url(../../images/icons/icon__email_light.svg);
}

.link_with-icon[href*='t.me']::before {
  background-image: url(../../images/icons/icon__telegram_light.svg);
}

.link_with-icon[href*='stackoverflow.com']::before {
  background-image: url(../../images/icons/icon__stackoverflow_light.svg);
}

.link_with-icon[href*='codewars.com']::before {
  background-image: url(../../images/icons/icon__codewars_light.svg);
}

.link_with-icon[href*='leetcode.com']::before {
  background-image: url(../../images/icons/icon__leetcode_light.svg);
}

.link_with-icon[href*='codepen.io']::before {
  background-image: url(../../images/icons/icon__codepen-link_light.svg);
}

.link_with-info::after {
  content: attr(data-content);
  display: inline-block;
  vertical-align: 35%;
  font-size: 0.7em;
  font-weight: 300;
  margin-left: 0.3em;
  color: inherit;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.link_with-info:hover::after {
  opacity: 1;
}

.link_type_local {
  text-decoration-style: dashed;
}
