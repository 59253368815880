.checkbox {
  cursor: pointer;
  transition: opacity 0.2s;
}

/* Скрытие системного чекбокса */
.checkbox__system-checkbox {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/* Надпись */
.checkbox__label {
  display: block;
  text-decoration: underline 1px dashed silver;
  text-underline-offset: 0.2em;
  padding-block: 0.5em;
  border-radius: 10px;
  padding: 8px;
  transition: box-shadow 0.2s, background-color 0.2s, opacity 0.2s,
    text-decoration-color 0.2s, transform 0.2s;
}
.checkbox__label:hover {
  text-decoration-color: inherit;
}

.checkbox__system-checkbox:checked + .checkbox__label {
  transform: translateY(2px);
  box-shadow: inset 3px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  opacity: 0.9;
}
