@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('Merriweather-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('Merriweather-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('Merriweather-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('Merriweather-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('Merriweather-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('Merriweather-BlackItalic.ttf') format('truetype');
}
