@keyframes bounce {
  from {
    transform: translate(-50%, -15%);
  }
  to {
    transform: translate(-50%, 15%);
  }
}

.navtab {
  position: relative;
  padding-block: 0px;
  padding-bottom: 64px;
}

.navtab::after {
  content: '↓';
  position: absolute;
  bottom: 8px;
  left: 50%;
  font-size: 32px;
  font-weight: 100;
  opacity: 0.5;
  animation: bounce 1s infinite ease-in-out alternate;
}

.navtab__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
