/* Файл normalize.css */
@import url(../../vendor/normalize.css);

/* Шрифты */
@import url(../../vendor/fonts/merriweather/merriweather.css);
@import url(../../vendor/fonts/inter-web/inter.css);

html {
  /* Плавная прокрутка */
  scroll-behavior: smooth;
}

/* Общий стиль для приложения */
.page {
  /* Сглаживание */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  /* Шрифт для всей страницы */
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  line-height: 1.3;
  /* Цветовая схема */
  --surface: #fff;
  --on-surface: #1d1d1d;
  /* Цвета страницы по умолчанию */
  background-color: var(--surface);
  color: var(--on-surface);
}

.section {
  width: 100%;
  max-width: 1100px;
  min-width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-inline: 32px;
}

@media (min-width: 768px) {
  .section {
    padding-inline: 48px;
  }
}

@media (min-width: 1024px) {
  .section {
    padding-inline: 64px;
  }
}

/* Общие правила */
a {
  color: inherit;
  text-decoration: underline silver 1px;
  transition: text-decoration-color 0.2s;
  text-underline-offset: 0.2em;
}

a:hover {
  text-decoration-color: inherit;
}
