/* Ссылки */
.card__link {
  text-decoration: none;
  color: inherit;
}

.card__title::after {
  content: '';
  display: inline-block;
  width: 0.7em;
  aspect-ratio: 1;
  opacity: 0.5;
  margin-left: 0.3em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card__link:hover + .card__description .card__title::after,
.card__link:hover .card__title::after {
  background-image: url(../../images/icons/icon__external-link.svg);
}

.card__link[href*='github.com']:hover + .card__description .card__title::after,
.card__link[href*='github.com']:hover .card__title::after {
  background-image: url(../../images/icons/icon__github-link.svg);
}

.card__link[href*='codepen.io']:hover + .card__description .card__title::after,
.card__link[href*='codepen.io']:hover .card__title::after {
  background-image: url(../../images/icons/icon__codepen-link.svg);
}

/* Картинка */
.card__image-container {
  position: relative;
  height: auto;
  aspect-ratio: 1;
}

.card_type_base .card__image-container {
  aspect-ratio: 2 /1;
}

.card__image {
  display: block;
  width: 100%;
  height: 100%;
  outline: 0.5px solid gainsboro;
  background-color: #fafafa;
  border-radius: 5px;
  object-fit: cover;
  object-position: top center;
  transition: transform 0.3s;
  will-change: transform;
}

.card__image-container::before,
.card__image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  outline: 0.5px solid gainsboro;
  outline-offset: -1px;
  border-radius: 5px;
  transition: transform 0.3s;
  /* background-color: lightgray;
  opacity: 0.5; */
}

.card:hover .card__image {
  transform: rotate(-0.5deg);
}

.card:hover .card__image-container::before {
  transform: rotate(4deg);
}

.card:hover .card__image-container::after {
  transform: rotate(9deg);
  transition: transform 0.6s;
}

.card__image-container::before {
  transform: rotate(3deg);
}

.card__image-container::after {
  transform: rotate(6deg);
  transition: transform 0.6s;
}

.card_important .card__image-container::before,
.card_important .card__image-container::after {
  outline: none;
  background-color: grey;
  opacity: 0.4;
}

.card_type_base:hover .card__image {
  transform: none;
}

.card_type_base:hover .card__image-container::before {
  transform: rotate(3deg);
}

.card_type_base:hover .card__image-container::after {
  transform: rotate(6deg);
}

/* Текст */
.card__description {
  margin-top: 8px;
}

.card__title {
  margin: 0;
  margin-bottom: 10px;
  font-family: 'Merriweather', serif;
  font-size: 24px;
  line-height: 1.2;
  font-weight: normal;
}

.card__text {
  margin: 0;
  margin-bottom: 8px;
}

.card__date {
  margin: 0;
  color: #888;
  font-family: sans-serif;
  width: fit-content;
}

.card__date_active::after {
  content: '';
  display: inline-block;
  width: 1.3em;
  aspect-ratio: 2;
  margin-left: 0.5em;
  background-image: url(../../images/icons/icon__active.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
