.cards__title {
  margin: 0;
  margin-bottom: 1em;
  margin-left: -0.5em;
  width: fit-content;
  border-radius: 5px;
  padding-inline: 0.5em;

  position: sticky;
  top: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;

  font-family: 'Merriweather', serif;
  font-size: 32px;
}

.cards__cards {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-bottom: 64px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 32px;
  row-gap: 96px;
}

@media (min-width: 768px) {
  .cards__cards {
    column-gap: 48px;
    row-gap: 96px;
  }
}

@media (min-width: 1024px) {
  .cards__cards {
    column-gap: 64px;
    row-gap: 96px;
  }
}

.cards__error {
  padding: 0;
  margin: 0;
  margin-bottom: 64px;
  opacity: 0.5;
}
