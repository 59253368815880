.contacts {
  padding-block: 32px;
  background: url(../../images/noise.gif);
  background-size: 300px auto;
  background-color: #494949;
}

.contacts__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
}

.contacts__item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* backdrop-filter: blur(1px); */
  padding: 8px;
  border-radius: 5px;
  color: #fff;
}
